<template>
  <!--
        Filtra por nome ou CNPJ
    -->
  <div class="row">
    <div class="col-12 col-md-4">
      <input-text
        v-model="filtro"
        :label="$t('GERAL.FILTRAR_POR_NOME_CPNJ')"
        :placeholder="$t('GERAL.FILTRAR_POR_NOME_CPNJ')"
        @enter="filtrar"
      />
    </div>
    <div class="col-12 col-md-auto mb-2">
      <b-button
        class="w-100 botao-acao-filtro mb-3 mb-md-0"
        variant="primary"
        @click="filtrar"
      >
        {{ $t('GERAL.PESQUISAR') }}
      </b-button>
    </div>
    <div class="col-12 col-md-auto mb-2">
      <b-button
        class="w-100 botao-acao-filtro mb-3 mb-md-0"
        variant="secondary"
        @click="limparFiltro"
      >
        {{ $t('GERAL.LIMPAR') }}
      </b-button>
    </div>
  </div>
</template>
<script>
import { InputText } from '@/components/inputs';
export default {
  components: {
    InputText,
  },
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      filtro: '',
    };
  },
  methods: {
    filtrar() {
      this.$emit('resultado', this.filtro);
    },
    limparFiltro() {
      this.filtro = '';
      return this.$emit('limpar');
    },
  },
};
</script>
