<template>
  <div>
    <div>
      <titulo-instituicao-ensino @cadastrar="irPara" />

      <filtro
        class="my-2"
        :items="instituicoes"
        @resultado="realizarBusca"
        @limpar="getInstituicoes"
      />

      <tabela :instituicoes="instituicoes" @excluir="confirmarExclusao" />
    </div>

    <paginacao
      v-if="!paginacaoFiltroAtivo"
      :total="total"
      v-model="paginacao"
      @input="getInstituicoes"
    />
    <paginacao
      v-else
      :total="totalFiltro"
      v-model="paginacaoFiltro"
      @input="filtrar"
    />
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import Permisoes from '@/components/mixins/permissoes';
import mensagem from '@/common/utils/mensagem';
import rotas from '@/common/utils/rotas';

// Services:
import InstituicaoEnsinoService from '@/common/services/instituicao-ensino/instituicao-ensino.js';

// Components:
import TituloInstituicaoEnsino from '@/views/academico/outras-instituicoes/instituicao-ensino/components/TituloInstituicaoEnsino';
import Filtro from '@/components/filtro/FiltroNomeCNPJ';
import Tabela from './components/Tabela';
import Paginacao from '@/components/paginacao/Paginacao';

export default {
  name: 'Index',
  mixins: [Permisoes],
  components: {
    TituloInstituicaoEnsino,
    Filtro,
    Tabela,
    Paginacao,
  },
  data() {
    return {
      instituicoes: [],
      filtro: '',
      total: 0,
      totalFiltro: 0,
      paginacao: { numeroDaPagina: 1, tamanhoDaPagina: 10 },
      paginacaoFiltro: { numeroDaPagina: 1, tamanhoDaPagina: 10 },
      paginacaoFiltroAtivo: false,
    };
  },
  mounted() {
    this.getInstituicoes();
  },
  methods: {
    realizarBusca(filtro) {
      filtro ? (this.filtro = filtro) : '';
      this.filtrar();
    },
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getInstituicoes() {
      this.$store.dispatch(START_LOADING);
      InstituicaoEnsinoService.obterPaginado(this.paginacao)
        .then(({ data }) => {
          this.instituicoes = data.itens;
          this.total = data.paginacao.totalDeElementos;
          this.paginacaoFiltroAtivo = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    filtrar() {
      this.$store.dispatch(START_LOADING);
      InstituicaoEnsinoService.obterPorFiltro(this.filtro, this.paginacaoFiltro)
        .then(({ data }) => {
          this.instituicoes = data.itens;
          this.totalFiltro = data.paginacao.totalDeElementos;
          this.paginacaoFiltroAtivo = true;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES CRUD:
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('ACADEMICO.CONFIRMAR_EXCLUSAO_DE').formatUnicorn({
          item: item.nome,
        }),
        this.$t('ACADEMICO.AVISO_NAO_PODE_DESFAZER_ACAO')
      );
      respostaExclusao.isConfirmed ? this.excluir(item.id) : {};
    },
    excluir(item) {
      this.$store.dispatch(START_LOADING);
      InstituicaoEnsinoService.excluir(item)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Instituição deletada!');
          this.getInstituicoes();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES AUXILIARES:
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
