<template>
  <b-table
    :items="instituicoes"
    :fields="campos"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    responsive="sm"
    show-empty
    stacked="md"
    striped
    hover
  >
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item
          @click="irPara('editar-instituicao-ensino', item.item.id)"
        >
          {{ $t('GERAL.EDITAR') }}
        </b-dropdown-item>
        <b-dropdown-item @click="excluir(item.item)">
          {{ $t('GERAL.EXCLUIR') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
// Utils & Aux:
import rotas from '@/common/utils/rotas';

export default {
  props: {
    instituicoes: { type: Array, default: Array },
  },
  data() {
    return {
      campos: [
        {
          key: 'nome',
          label: this.$t('INSTITUICAO_ENSINO.NOME'),
        },
        {
          key: 'cnpj',
          label: this.$t('INSTITUICAO_ENSINO.CNPJ'),
          formatter: (value) => {
            let cnpj = value?.replace(/\D/g, '');
            cnpj = cnpj?.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
              '$1.$2.$3/$4-$5'
            );
            return cnpj;
          },
        },
        {
          key: 'razaoSocial',
          label: this.$t('INSTITUICAO_ENSINO.RAZÃO_SOCIAL'),
        },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
    };
  },
  methods: {
    // FUNÇÕES CRUD:
    excluir(item) {
      this.$emit('excluir', item);
    },
    // FUNÇÕES AUXILIARES:
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
