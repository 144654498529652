<template>
  <topbar
    :titulo="$t('ACADEMICO.INSTITUICAO_ENSINO')"
    :subtitulo="$t('ACADEMICO.INSTITUICAO_ENSINO_CEJ_DESCRICAO')"
    :breadcrumbs="breadcrumbs"
  >
    <template #header>
      <div class="d-flex justify-content-between ml-md-4 gap-1">
        <div></div>
        <b-button variant="info" @click="irPara('criar-instituicao-ensino')">
          {{ $t('GERAL.CADASTRAR') }}
        </b-button>
      </div>
    </template>
  </topbar>
</template>
<script>
// Utils & Aux:
import rotas from '@/common/utils/rotas';
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO.CURRICULO_ESCOLAR') },
        { titulo: this.$t('INSTITUICAO_ENSINO.INSTITUICAO_ENSINO') },
      ],
    };
  },
  methods: {
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
